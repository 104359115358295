<template>
  <b-tab
      lazy
  >
    <template #title>
      <feather-icon
          v-if="formData.status.value === 'invalid'"
          icon="XIcon"
          size="21"
          color="#dc3545"
      />
      <feather-icon
          v-else-if="formData.status.value === 'valid'"
          icon="CheckIcon"
          size="21"
          color="#28a745"
      />
      <strong>
        {{ $t('tab.title.billingo') }}
      </strong>
    </template>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="12">
            <b-form-group
                :label="$t('form.apiKey')"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="$t('form.apiKey').toLowerCase()"
                  rules="required"
                  vid="apiKey"
              >
                <b-form-input
                    v-model="formData.apiKey"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('form.apiKey').toLowerCase()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- submit button -->
          <b-col class="mt-1">
            <b-button
                variant="primary"
                type="submit"
                class="float-right"
                @click.prevent="submitForm"
            >
              {{ $t('label.button.save') }}
            </b-button>
          </b-col>

        </b-row>
      </b-form>
    </validation-observer>
  </b-tab>
</template>

<script>
import {ValidationObserver, ValidationProvider} from "vee-validate"
import {BTab, BForm, BRow, BCol, BFormGroup, BFormInput, BButton} from "bootstrap-vue"

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BTab,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton
  },
  props: {
    entityId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      role: 'client',
      formData: {
        clientId: '',
        apiKey: '',
        status: {
          value: 'invalid',
          title: 'invalid'
        }
      },
      statusOptions: []
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      this.$store.dispatch('fetchClientBillingSystemCredentials', this.entityId).then(response => {
        this.formData = response.data.entity

        this.$store.dispatch('fetchClientBillingSystemCredentialStatuses').then(stResponse => {
          if (stResponse.data.values) {
            for (const key in stResponse.data.values) {
              this.statusOptions.push({
                value: stResponse.data.values[key].value,
                title: this.$t('clients.billingSystemCredentialStatuses.' + stResponse.data.values[key].title),
              })
            }

            if (this.statusOptions.length) {
              Object.keys(this.statusOptions).forEach(key => {
                if (this.statusOptions[key].value === response.data.entity.status) {
                  this.formData.status = this.statusOptions[key]
                }
              })
            }
          }
        })
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let fd = JSON.parse(JSON.stringify(this.formData))

          this.$store.dispatch('updateClientBillingSystemCredentials', fd).then(() => {
            this.$helpers.showSuccessToast()

            this.initData()
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.response.data.message)
            }
          })
        }
      })
    }
  },
}
</script>
