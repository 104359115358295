<template>
  <b-tab
      lazy
  >
    <template #title>
      <feather-icon
          icon="UserPlusIcon"
          size="21"
      />
      <strong>
        {{ $t('tab.title.contacts') }}
      </strong>
    </template>
    <b-row>
      <b-col
          xs="12"
      >
        <div v-if="this.$acl.canCreatePermission(role)"  class="ml-1 mb-1">
          <b-button
              variant="success"
              size="sm"
              @click="showAddModal"
          >
            <span v-if="!this.$helpers.isMobileResolution() && !this.$helpers.isSmallResolution()" class="text-nowrap">{{ $t('label.button.add') }}</span>
            <feather-icon v-else icon="PlusIcon" />
          </b-button>
        </div>
      </b-col>
    </b-row>
    <!-- table -->
    <vue-good-table
        mode="remote"
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        @on-row-click="onRowClick"
        @on-sort-change="onSortChange"
        :pagination-options="{
          enabled: true,
          perPage: pageLength
        }"
    >
      <div slot="emptystate">
        <div class="vgt-center-align vgt-text-disabled">
          {{ $t('table.list.empty') }}
        </div>
      </div>
      <template
          slot="table-row"
          slot-scope="props"
      >

        <!-- Column: Name -->
        <span
            v-if="props.column.field === 'status'"
            class="text-nowrap"
        >
                <span class="text-nowrap">{{ $t('contacts.statuses.' + props.row.status) }}</span>
              </span>
        <span
            v-else-if="props.column.field === 'type'"
            class="text-nowrap"
        >
                <span class="text-nowrap">{{ $t('contacts.types.' + props.row.type) }}</span>
              </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span v-if="$acl.canListPermission(role)">
              <b-button
                  size="sm"
                  variant="info"
                  @click.stop="showShowModal(props.row.id)"
              >
                <feather-icon
                    icon="EyeIcon"
                />
              </b-button>
            </span>
          <span v-if="$acl.canEditPermission(role)">
              <b-button
                  size="sm"
                  variant="primary"
                  class="ml-1"
                  @click.stop="showModifyModal(props.row.id)"
              >
                <feather-icon
                    icon="EditIcon"
                />
              </b-button>
            </span>
            <span v-if="$acl.canDeletePermission(role)">
              <b-button
                  size="sm"
                  variant="danger"
                  class="ml-1"
                  @click.stop="deleteEntity(props.row.id)"
              >
                <feather-icon
                    icon="XCircleIcon"
                />
              </b-button>
            </span>
          </span>

        <!-- Column: Common -->
        <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ ((page - 1) * pageLength) + 1 }} +
            </span>
            <b-form-select
                v-model="pageLength"
                :options="pages"
                class="mx-1"
                @change="handlePageChange"
            />
            <span class="text-nowrap "> a {{ dataFromServer.total }} találatból </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="dataFromServer.total"
                :per-page="dataFromServer.per_page"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <add-contact
        ref="addContactModal"
        :client-id="entityId"
        @refresh-contacts="initData"
    />
    <modify-contact
        ref="modifyContactModal"
        @refresh-contacts="initData"
    />
    <show-contact
        ref="showContactModal"
    />
  </b-tab>
</template>

<script>
import {
  BTab, BPagination, BFormSelect, BDropdown, BDropdownItem, BButton, BRow, BCol,
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store/index'
import addContact from "@/views/pages/_components/_modal/_contact/addContact.vue";
import modifyContact from "@/views/pages/_components/_modal/_contact/modifyContact.vue";
import showContact from "@/views/pages/_components/_modal/_contact/showContact.vue";

export default {
  components: {
    BTab,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
    addContact,
    modifyContact,
    showContact,
  },
  props: {
    entityId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      role: 'contact',
      pageLength: 10,
      dir: false,
      pages: ['10', '20', '50'],
      columns: [
        {
          label: this.$t('form.name'),
          field: 'user.name',
          sortable: false,
        },
        {
          label: this.$t('form.email'),
          field: 'user.email',
          sortable: false,
        },
        {
          label: this.$t('form.phone'),
          field: 'phone',
          sortable: false,
        },
        {
          label: this.$t('form.type'),
          field: 'type',
          sortable: false,
        },
        {
          label: this.$t('form.status'),
          field: 'status',
          sortable: false,
        },
      ],
      rows: [],
      page: 1,
      dataFromServer: [],
      sortBy: {
        field: 'id',
        type: 'desc',
      },
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    let rems = 0;
    if (this.$acl.canListPermission(this.role)) {
      rems+=10
    }
    if (this.$acl.canEditPermission(this.role) || this.$acl.canDeletePermission(this.role)) {
      rems+=5
    }

    if (this.$acl.canListPermission(this.role) || this.$acl.canEditPermission(this.role) || this.$acl.canDeletePermission(this.role)) {
      const actions = [
        {
          label: this.$t('table.header.actions'),
          field: 'action',
          sortable: false,
          width: rems+'rem',
          tdClass: 'text-center'
        },
      ]

      this.columns = [...this.columns, ...actions]
    }

    this.initData()
  },
  methods: {
    initData() {
      const searchParams = {
        page: parseInt(this.page),
        limit: parseInt(this.pageLength),
        query: this.searchTerm,
        columnFilters: {
          clientId: this.entityId,
        },
        sort: this.sortBy,
      }

      this.$store.dispatch('fetchContacts', searchParams).then(response => {
        this.rows = response.data.entities.data
        this.dataFromServer = response.data.entities
        this.page = this.dataFromServer.current_page
        this.pageLength = parseInt(this.dataFromServer.per_page)
      })
    },
    onRowClick(params) {
      if (this.$acl.canEditPermission(this.role)) {
        this.showModifyModal(params.row.id)
      }else if (this.$acl.canListPermission(this.role)) {
        this.showShowModal(params.row.id)
      }
    },
    showAddModal(){
      this.$refs.addContactModal.initData()
      this.$bvModal.show('form-add-contact-modal')
    },
    showModifyModal(id){
      this.$refs.modifyContactModal.formData.id = id
      this.$refs.modifyContactModal.initData()
      this.$bvModal.show('form-modify-contact-modal')
    },
    showShowModal(id){
      this.$refs.showContactModal.formData.id = id
      this.$refs.showContactModal.initData()
      this.$bvModal.show('form-show-contact-modal')
    },
    handleChangePage(page) {
      this.page = page
      this.initData()
    },
    handlePageChange(active) {
      this.pageLength = active
      this.page = 1
      this.handleChangePage()
    },
    onSortChange(params) {
      this.page = 1
      this.sortBy = params[0]
      this.initData()
    },
    deleteEntity(id) {
      this.$bvModal
          .msgBoxConfirm(this.$t('confirm.message.delete'), {
            title: this.$t('modal.title.confirm'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: this.$helpers.ucFirst(this.$t('label.yes')),
            cancelTitle: this.$helpers.ucFirst(this.$t('label.no')),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              // axios
              this.$store.dispatch('deleteContact', id).then(() => {
                this.initData(1)
                this.$helpers.showSuccessToast(this.$t('text.api.response.deleteSuccessfully', {id}))
              })
            }
          })
    },
  },
}
</script>
