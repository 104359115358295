<template>
  <b-modal
      id="form-show-contact-modal"
      ref="form-show-contact-modal"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      :title="$t('card.title.show.contact')"
  >
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="12">
            <b-form-group
                :label="$t('form.user')"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="$t('form.user').toLowerCase()"
                  rules=""
                  vid="user"
              >
                <b-form-input
                    v-model="formData.user"
                    readonly
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('form.user').toLowerCase()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
                :label="$t('form.phone')"
            >
              <validation-provider
                  ref="phoneValidator"
                  #default="{ errors }"
                  :name="$t('form.phone').toLowerCase()"
                  rules="required"
                  vid="phone"
              >
                <VuePhoneNumberInput
                    v-model="formData.phone"
                    :error="errors.length > 0"
                    :placeholder="$t('form.phone').toLowerCase()"
                    default-country-code="HU"
                    color="#42968E"
                    valid-color="#404656"
                    error-color="#EA5455"
                    dark-color="#283046"
                    :disabled="true"
                    :dark="$store.state.appConfig.layout.skin === 'dark'"
                    :translations="{
                      countrySelectorLabel: $t('form.country'),
                      countrySelectorError: $t('phoneNumberInput.error'),
                      phoneNumberLabel: $t('form.phone'),
                      example: $t('phoneNumberInput.example')
                    }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
                :label="$t('form.type')"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="$t('form.type').toLowerCase()"
                  :clearable="false"
                  rules="required"
                  vid="type"
              >
                <v-select
                    v-model="formData.type"
                    :disabled="true"
                    :clearable="false"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="typeOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
                :label="$t('form.status')"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="$t('form.status').toLowerCase()"
                  :clearable="false"
                  rules="required"
                  vid="status"
              >
                <v-select
                    v-model="formData.status"
                    :disabled="true"
                    :clearable="false"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="statusOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>

import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import BCardCode from "@core/components/b-card-code";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    vSelect,
    VuePhoneNumberInput,
  },
  data() {
    return {
      role: 'contact',
      formData: {
        id: '',
        client: '',
        user: '',
        phone: '',
        phoneInternational: '',
        status: '',
        type: '',
      },
      userOptions: [],
      statusOptions: [],
      typeOptions: [],
      required,
    }
  },
  methods: {
    initData(){

      this.statusOptions = []
      this.typeOptions = []

      this.$store.dispatch('fetchContact', this.formData.id).then(response => {
        this.formData.user = response.data.entity.user.name
        this.formData.phone = response.data.entity.phone

        this.$store.dispatch('fetchContactStatuses').then(stResponse => {
          if (stResponse.data.values) {
            for (const key in stResponse.data.values) {
              this.statusOptions.push({
                value: stResponse.data.values[key].value,
                title: this.$t('contacts.statuses.' + stResponse.data.values[key].title),
              })
            }

            if (this.statusOptions.length) {
              Object.keys(this.statusOptions).forEach(key => {
                if (this.statusOptions[key].value === response.data.entity.status) {
                  this.formData.status = this.statusOptions[key]
                }
              })
            }
          }
        })

        this.$store.dispatch('fetchContactTypes').then(tyResponse => {
          if (tyResponse.data.values) {
            for (const key in tyResponse.data.values) {
              this.typeOptions.push({
                value: tyResponse.data.values[key].value,
                title: this.$t('contacts.types.' + tyResponse.data.values[key].title),
              })
            }

            if (this.typeOptions.length) {
              Object.keys(this.typeOptions).forEach(key => {
                if (this.typeOptions[key].value === response.data.entity.type) {
                  this.formData.type = this.typeOptions[key]
                }
              })
            }
          }
        })

      })
    },
  },
}
</script>
