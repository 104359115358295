<template>
  <b-modal
      id="form-add-contact-modal"
      ref="form-add-contact-modal"
      cancel-variant="outline-secondary"
      :ok-title="$t('label.button.save')"
      :cancel-title="$t('label.button.cancel')"
      @ok="handleOk"
      centered
      :title="$t('card.title.add.contact')"
  >
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="12">
            <b-form-group
                :label="$t('form.user')"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="$t('form.user').toLowerCase()"
                  :clearable="false"
                  rules="required"
                  vid="user"
              >
                <v-select
                    v-model="formData.user"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="userOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
                :label="$t('form.phone')"
            >
              <validation-provider
                  ref="phoneValidator"
                  #default="{ errors }"
                  :name="$t('form.phone').toLowerCase()"
                  rules="required"
                  vid="phone"
              >
                <VuePhoneNumberInput
                    v-model="formData.phone"
                    :error="errors.length > 0"
                    :placeholder="$t('form.phone').toLowerCase()"
                    default-country-code="HU"
                    color="#42968E"
                    valid-color="#404656"
                    error-color="#EA5455"
                    dark-color="#283046"
                    :dark="$store.state.appConfig.layout.skin === 'dark'"
                    :translations="{
                      countrySelectorLabel: $t('form.country'),
                      countrySelectorError: $t('phoneNumberInput.error'),
                      phoneNumberLabel: $t('form.phone'),
                      example: $t('phoneNumberInput.example')
                    }"
                    @update="phoneChanged"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
                :label="$t('form.type')"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="$t('form.type').toLowerCase()"
                  :clearable="false"
                  rules="required"
                  vid="type"
              >
                <v-select
                    v-model="formData.type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="typeOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
                :label="$t('form.status')"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="$t('form.status').toLowerCase()"
                  :clearable="false"
                  rules="required"
                  vid="status"
              >
                <v-select
                    v-model="formData.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="statusOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>

import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import BCardCode from "@core/components/b-card-code";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    vSelect,
    VuePhoneNumberInput,
  },
  props: {
    clientId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      role: 'contact',
      formData: {
        client: '',
        user: '',
        phone: '',
        phoneInternational: '',
        status: '',
        type: '',
      },
      skipUserIds: [],
      userOptions: [],
      statusOptions: [],
      typeOptions: [],
      required,
    }
  },
  methods: {
    initData(){

      this.formData.user = ''
      this.skipUserIds = []
      this.userOptions = []
      this.statusOptions = []
      this.typeOptions = []

      this.$store.dispatch('fetchClientSkipUserIds', this.clientId).then(response => {
        this.skipUserIds = response.data.ids
      }).finally(() => {
        this.$store.dispatch('fetchUsersForSelect').then(response => {
          for (const key in response) {
            if(
                response[key].role !== 'Supervisor' &&
                response[key].role !== 'Admin' &&
                this.skipUserIds.indexOf(response[key].value) === -1
            ){
              this.userOptions.push({
                value: response[key].value,
                title: response[key].title,
              })
            }
          }

          if (this.userOptions.length) {
            // eslint-disable-next-line prefer-destructuring
            this.formData.user = this.userOptions[0]
          }
        })
      })

      this.$store.dispatch('fetchContactStatuses').then(response => {
        if (response.data.values) {
          for (const key in response.data.values) {
            this.statusOptions.push({
              value: response.data.values[key].value,
              title: this.$t('contacts.statuses.' + response.data.values[key].title),
            })
          }

          if (this.statusOptions.length) {
            // eslint-disable-next-line prefer-destructuring
            this.formData.status = this.statusOptions[0]
          }
        }
      })

      this.$store.dispatch('fetchContactTypes').then(response => {
        if (response.data.values) {
          for (const key in response.data.values) {
            this.typeOptions.push({
              value: response.data.values[key].value,
              title: this.$t('contacts.types.' + response.data.values[key].title),
            })
          }

          if (this.typeOptions.length) {
            // eslint-disable-next-line prefer-destructuring
            this.formData.type = this.typeOptions[0]
          }
        }
      })
    },
    phoneChanged(e) {
      if (!e.isValid) {
        this.$refs.phoneValidator.validate().then(() => {
          const err = {
            phone: [this.$t('validation.phone')]
          }
          this.$refs.simpleRules.setErrors(err)
        })
      }
      this.formData.phoneInternational = e.formatInternational
    },
    handleOk(e) {
      e.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // clone formData
          let fd = JSON.parse(JSON.stringify(this.formData))
          fd.client = this.clientId
          fd.user = this.formData.user.value
          fd.status = this.formData.status.value
          fd.type = this.formData.type.value
          fd.phone = this.formData.phoneInternational

          this.$store.dispatch('storeContact', fd).then(() => {
            this.$helpers.showSuccessToast()
            this.$emit('refresh-contacts')
            this.$nextTick(() => {
              this.$bvModal.hide('form-add-contact-modal')
            })
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.response.data.message)
            }
          })
        }
      })
    },
  },
}
</script>
