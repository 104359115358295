<template>
  <div>
    <b-card no-title>
      <b-row>
        <b-col
            xs="12"
            class="mx-auto"
        >
          <b-card-title>
            {{ $t('card.title.modify.client') }}
            <div v-if="this.$acl.canDeletePermission(role)" class="float-right mr-1">
              <b-button
                  variant="danger"
                  size="sm"
                  v-b-tooltip.hover
                  :title="$t('label.button.delete')"
                  @click="deleteEntity(formData.deletable)"
              >
                <span v-if="!this.$helpers.isMobileResolution() && !this.$helpers.isSmallResolution()"
                      class="text-nowrap">{{ $t('label.button.delete') }}</span>
                <feather-icon v-else icon="XCircleIcon"/>
              </b-button>
            </div>
          </b-card-title>
        </b-col>
      </b-row>
      <b-card-text>
        {{ $t('card.description.modify.client') }}
      </b-card-text>
    </b-card>
    <b-overlay :show="loading">
      <b-card-code>
        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col md="4">
                <b-form-group
                    :label="$t('form.primaryContact')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.primaryContact').toLowerCase()"
                      rules="required"
                      vid="user"
                  >
                    <v-select
                        v-model="formData.user"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :clearable="false"
                        :disabled="$store.getters.userRole !== 'Supervisor' && $store.getters.userRole !== 'Admin'"
                        :options="userOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    :label="$t('form.taxNumber')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.taxNumber').toLowerCase()"
                      :rules="{required, taxNumber:formData.country.value.toUpperCase()}"
                      vid="taxNumber"
                      ref="taxNumberIsValid"
                  >
                    <b-input-group>
                      <b-input-group-prepend v-if="formData.country.value.toUpperCase() !== 'HU'">
                        <b-input-group-text>
                          {{ formData.country.value.toUpperCase() }}
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                          v-model="formData.taxNumber"
                          @input="queryTaxNumber"
                          :state="errors.length > 0 ? false:null"
                          :placeholder="$t('form.taxNumber').toLowerCase()"
                          :formatter="taxNumberFormatter"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    :label="$t('form.companyName')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.companyName').toLowerCase()"
                      rules="required"
                      vid="companyName"
                  >
                    <b-form-input
                        v-model="formData.companyName"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('form.companyName').toLowerCase()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    :label="$t('form.country')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.country').toLowerCase()"
                      rules=""
                      vid="country"
                  >
                    <v-select
                        v-model="formData.country"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :clearable="false"
                        :options="countryOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    :label="$t('form.zip')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.zip').toLowerCase()"
                      rules=""
                      vid="zip"
                  >
                    <b-form-input
                        v-model="formData.zip"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('form.zip').toLowerCase()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    :label="$t('form.city')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.city').toLowerCase()"
                      rules=""
                      vid="city"
                  >
                    <b-form-input
                        v-model="formData.city"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('form.city').toLowerCase()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                    :label="$t('form.street')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.street').toLowerCase()"
                      rules=""
                      vid="street"
                  >
                    <b-form-input
                        v-model="formData.street"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('form.street').toLowerCase()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                    :label="$t('form.houseNumber')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.houseNumber').toLowerCase()"
                      rules=""
                      vid="houseNumber"
                  >
                    <b-form-input
                        v-model="formData.houseNumber"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('form.houseNumber').toLowerCase()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                    :label="$t('form.houseNumberInfo')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.houseNumberInfo').toLowerCase()"
                      rules=""
                      vid="houseNumberInfo"
                  >
                    <b-form-input
                        v-model="formData.houseNumberInfo"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('form.houseNumberInfo').toLowerCase()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group
                    :label="$t('form.status')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.status').toLowerCase()"
                      rules="required"
                      vid="status"
                  >
                    <v-select
                        v-model="formData.status"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :clearable="false"
                        :disabled="$store.getters.userRole !== 'Supervisor' && $store.getters.userRole !== 'Admin'"
                        :options="statusOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                    :label="$t('form.legalPerson')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.legalPerson').toLowerCase()"
                      rules="required"
                      vid="legalPerson"
                  >
                    <v-select
                        v-model="formData.legalPerson"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :clearable="true"
                        :disabled="$store.getters.userRole !== 'Supervisor' && $store.getters.userRole !== 'Admin'"
                        :options="legalPersonOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                    :label="$t('form.billingSystem')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.billingSystem').toLowerCase()"
                      rules=""
                      vid="billingSystem"
                  >
                    <v-select
                        v-model="formData.billingSystem"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :clearable="true"
                        :options="billingSystemOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- submit button -->
              <b-col class="mt-1">
                <b-button
                    variant="primary"
                    type="submit"
                    class="float-right"
                    @click.prevent="submitForm"
                >
                  {{ $t('label.button.save') }}
                </b-button>
                <cancel-and-g-o-t-o-previous-page class="mr-2"/>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-code>
    </b-overlay>
    <b-card-code>
      <tabs
          ref="tabs"
          :billing-system="formData.billingSystem.value"
          :entity-id="parseInt(this.$route.params.id)"
      />
    </b-card-code>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BCard, BCardTitle, BCardText, BForm, BRow, BCol, BButton, BFormCheckbox, BOverlay,
  BInputGroup, BInputGroupPrepend, BInputGroupText, VBTooltip
} from 'bootstrap-vue'
import {
  required, taxNumber
} from '@validations'
import vSelect from 'vue-select'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import {formatTaxNumber} from "@core/utils/filter"
import i18nHelper from "@/libs/helper/i18nHelper";
import tabs from '@/views/pages/_components/_tabs/_client/update.vue'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BCardText,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BOverlay,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupText,
    tabs,
    vSelect,
    cancelAndGOTOPreviousPage,
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
      role: 'client',
      formData: {
        id: null,
        legalPerson: {
          value: '',
          title: ''
        },
        user: {
          value: '',
          title: ''
        },
        companyName: '',
        taxNumber: '',
        country: {
          value: '',
          title: ''
        },
        zip: '',
        city: '',
        street: '',
        houseNumber: '',
        houseNumberInfo: '',
        status: {
          value: '',
          title: ''
        },
        billingSystem: {
          value: '',
          title: ''
        },
      },
      legalPersonOptions: [],
      userOptions: [],
      countryOptions: [],
      statusOptions: [],
      billingSystemOptions: [],
      loading: false,
      required,
      taxNumber,
      formatTaxNumber
    }
  },
  created() {
    this.loading = false
    this.$store.dispatch('fetchClient', this.$route.params.id).then(response => {
      this.formData = response.data.entity
      this.formData.id = this.$route.params.id

      if(!response.data.entity.editable){
        this.$helpers.showErrorToast(this.$t('text.api.response.cantEdit'))
        this.$acl.canListPermission(this.role)
            ? this.$router.push({name: 'clients'})
            : this.$router.go(-1)
      }

      if(this.$store.getters.userRole === 'Supervisor' || this.$store.getters.userRole === 'Admin'){
        this.$store.dispatch('fetchUsersForSelect').then(usResponse => {
          this.userOptions = usResponse

          if (this.userOptions.length) {
            Object.keys(this.userOptions).forEach(key => {
              if (this.userOptions[key].value === response.data.entity.userId) {
                this.formData.user = this.userOptions[key]
              }
            })
          }
        })
      }else{
        this.formData.user = response.data.entity.user.name
      }

      if(this.$store.getters.userRole === 'Supervisor' || this.$store.getters.userRole === 'Admin'){
        this.$store.dispatch('fetchLegalPersonsForSelect').then(leResponse => {
          this.legalPersonOptions = leResponse

          if (this.legalPersonOptions.length) {
            Object.keys(this.legalPersonOptions).forEach(key => {
              if (this.legalPersonOptions[key].value === response.data.entity.legalPersonId) {
                this.formData.legalPerson = this.legalPersonOptions[key]
              }
            })
          }
        })
      }else{
        this.formData.legalPerson = response.data.entity.legalPerson.name
      }

      this.$store.dispatch('fetchCountriesForSelect').then(coResponse => {
        this.countryOptions = coResponse

        if (this.countryOptions.length) {
          Object.keys(this.countryOptions).forEach(key => {
            if (this.countryOptions[key].value === response.data.entity.country) {
              this.formData.country = this.countryOptions[key]
            }
          })
        }
      })

      this.$store.dispatch('fetchClientStatuses').then(stResponse => {
        if (stResponse.data.values) {
          for (const key in stResponse.data.values) {
            this.statusOptions.push({
              value: stResponse.data.values[key].value,
              title: this.$t('clients.statuses.' + stResponse.data.values[key].title),
            })
          }

          if (this.statusOptions.length) {
            Object.keys(this.statusOptions).forEach(key => {
              if (this.statusOptions[key].value === response.data.entity.status) {
                this.formData.status = this.statusOptions[key]
              }
            })
          }
        }
      })

      this.$store.dispatch('fetchClientBillingSystems').then(biResponse => {
        if (biResponse.data.values) {
          for (const key in biResponse.data.values) {
            this.billingSystemOptions.push({
              value: biResponse.data.values[key].value,
              title: this.$t('clients.billingSystems.' + biResponse.data.values[key].title),
            })
          }

          if (this.billingSystemOptions.length) {
            Object.keys(this.billingSystemOptions).forEach(key => {
              if (this.billingSystemOptions[key].value === response.data.entity.billingSystem) {
                this.formData.billingSystem = this.billingSystemOptions[key]
              }
            })
          }
        }
      })
    }).finally(() => {
      this.loading = false
    })
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // clone formData
          let fd = JSON.parse(JSON.stringify(this.formData))
          fd.legalPerson = this.formData.legalPerson.value
          fd.user = this.formData.user.value
          fd.country = this.formData.country.value
          fd.status = this.formData.status.value
          if(this.formData.billingSystem) {
            fd.billingSystem = this.formData.billingSystem.value
          }

          this.$store.dispatch('updateClient', fd).then(() => {
            this.$helpers.showSuccessToast()

            this.$acl.canListPermission(this.role)
                ? this.$router.push({name: 'clients'})
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    },
    queryTaxNumber() {
      this.$refs.taxNumberIsValid.validate().then(success => {
        if (success.valid) {
          this.loading = true
          let actionName
          let isoCode
          if (this.formData.country.value === 'HU') {
            actionName = 'getTaxNumberHu'
            isoCode = ''
          } else {
            actionName = 'getTaxNumberEu';
            isoCode = this.formData.country.value.toUpperCase()
          }

          this.$store.dispatch(actionName, {'taxNumber': isoCode + this.formData.taxNumber}).then(response => {
            let taxpayer = response.data.taxpayer

            console.log(taxpayer)

            this.formData.companyName = taxpayer.name
            this.formData.taxNumber = taxpayer.taxNumber
            this.formData.zip = taxpayer.zip
            this.formData.city = taxpayer.city
            this.formData.street = taxpayer.street
            this.formData.houseNumber = taxpayer.houseNumber
            this.formData.houseNumberInfo = taxpayer.houseNumberInfo

          }).catch(error => {
            if (error.response.status === 422) {
              this.clearAddressData()
              if (Object.keys(error.response.data).includes('errors')) {
                console.log(error.response.data.errors)
                this.$refs.simpleRules.setErrors(error.response.data.errors)
              } else {
                // TODO: set errors!
                this.$helpers.showErrorToast(error.response.data.message)
              }
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          }).finally(() => {
            this.loading = false;
          })
        }
      });
    },
    clearAddressData() {
      this.formData.companyName = ''
      this.formData.zip = ''
      this.formData.city = ''
      this.formData.street = ''
      this.formData.houseNumber = ''
      this.formData.houseNumberInfo = ''
    },
    taxNumberFormatter(value) {
      const countryCode = this.formData.country.value.toUpperCase()

      return formatTaxNumber(value, countryCode)
    },
    deleteEntity(deletable) {
      if (deletable) {
        this.$bvModal
            .msgBoxConfirm(this.$t('confirm.message.delete'), {
              title: this.$t('modal.title.confirm'),
              size: 'sm',
              okVariant: 'primary',
              okTitle: this.$helpers.ucFirst(this.$t('label.yes')),
              cancelTitle: this.$helpers.ucFirst(this.$t('label.no')),
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            })
            .then(value => {
              if (value) {
                const id = this.$route.params.id
                this.$store.dispatch('deleteClient', id).then(() => {
                  this.$helpers.showSuccessToast(this.$t('text.api.response.deleteSuccessfully', {id}))

                  this.$acl.canListPermission(this.role)
                      ? this.$router.push({name: 'clients'})
                      : this.$router.go(-1)
                })
              }
            })
      } else {
        this.$helpers.showErrorToast(this.$t('text.api.response.cantDelete'))
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
