var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-tab',{attrs:{"lazy":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.formData.status.value === 'invalid')?_c('feather-icon',{attrs:{"icon":"XIcon","size":"21","color":"#dc3545"}}):(_vm.formData.status.value === 'valid')?_c('feather-icon',{attrs:{"icon":"CheckIcon","size":"21","color":"#28a745"}}):_vm._e(),_c('strong',[_vm._v(" "+_vm._s(_vm.$t('tab.title.nav'))+" ")])]},proxy:true}])},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.navUsername')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.navUsername').toLowerCase(),"rules":"required","vid":"username"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.navUsername').toLowerCase()},model:{value:(_vm.formData.username),callback:function ($$v) {_vm.$set(_vm.formData, "username", $$v)},expression:"formData.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.navPassword')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.navPassword').toLowerCase(),"rules":"required","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.navPassword').toLowerCase()},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.navSignKey')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.navSignKey').toLowerCase(),"rules":"required","vid":"signKey"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.navSignKey').toLowerCase()},model:{value:(_vm.formData.signKey),callback:function ($$v) {_vm.$set(_vm.formData, "signKey", $$v)},expression:"formData.signKey"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.navExchangeKey')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.navExchangeKey').toLowerCase(),"rules":"required","vid":"exchangeKey"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.navExchangeKey').toLowerCase()},model:{value:(_vm.formData.exchangeKey),callback:function ($$v) {_vm.$set(_vm.formData, "exchangeKey", $$v)},expression:"formData.exchangeKey"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-1"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('label.button.save'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }