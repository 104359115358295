var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"form-add-contact-modal",attrs:{"id":"form-add-contact-modal","cancel-variant":"outline-secondary","ok-title":_vm.$t('label.button.save'),"cancel-title":_vm.$t('label.button.cancel'),"centered":"","title":_vm.$t('card.title.add.contact')},on:{"ok":_vm.handleOk}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.user')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.user').toLowerCase(),"clearable":false,"rules":"required","vid":"user"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.userOptions},model:{value:(_vm.formData.user),callback:function ($$v) {_vm.$set(_vm.formData, "user", $$v)},expression:"formData.user"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.phone')}},[_c('validation-provider',{ref:"phoneValidator",attrs:{"name":_vm.$t('form.phone').toLowerCase(),"rules":"required","vid":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VuePhoneNumberInput',{attrs:{"error":errors.length > 0,"placeholder":_vm.$t('form.phone').toLowerCase(),"default-country-code":"HU","color":"#42968E","valid-color":"#404656","error-color":"#EA5455","dark-color":"#283046","dark":_vm.$store.state.appConfig.layout.skin === 'dark',"translations":{
                    countrySelectorLabel: _vm.$t('form.country'),
                    countrySelectorError: _vm.$t('phoneNumberInput.error'),
                    phoneNumberLabel: _vm.$t('form.phone'),
                    example: _vm.$t('phoneNumberInput.example')
                  }},on:{"update":_vm.phoneChanged},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.type')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.type').toLowerCase(),"clearable":false,"rules":"required","vid":"type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.typeOptions},model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.status')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.status').toLowerCase(),"clearable":false,"rules":"required","vid":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.statusOptions},model:{value:(_vm.formData.status),callback:function ($$v) {_vm.$set(_vm.formData, "status", $$v)},expression:"formData.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }